/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/react-in-jsx-scope */
import { Calendar, Tag } from '@saleshandy/icons';
import { useHistory, useLocation } from 'react-router';
import {
  FilterComponentType,
  LeadFinderTabKey,
  SavedTabFilters,
} from '../../type';
import { getLeadsActiveTab } from '../../helpers/helper';
import { DateTime } from 'luxon';
import React from 'react';

let timeout: any;

export const FILTER_SAVED_LIST: any = [
  {
    id: 'filter-tag',
    key: 'tag',
    label: 'Tags',
    icon: <Tag />,
    type: [
      {
        id: 'filter-tag-checkboxes',
        key: 'tag',
        value: FilterComponentType.TAG_CHECKBOX,
        inputPlaceHolder: 'Select',
      },
    ],
  },
  {
    id: 'filter-date',
    key: 'createdDate',
    label: 'Date',
    icon: <Calendar />,
    type: [
      {
        id: 'filter-date',
        key: 'createdDate',
        value: FilterComponentType.SAVED_LEAD_DATE,
        inputPlaceHolder: 'Select',
      },
    ],
  },
];

export const useSavedLeadFilter = (activeTabKey) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);

  const handleDefaultValues = () => {
    const filters: SavedTabFilters = {
      tags: [],
      createdDate: [],
      search: "",
    };
    if (queryParams.has("keyword")) {
      filters["search"] = queryParams.get("keyword");
    }
    if (queryParams.has("tags")) {
      filters["tags"] = queryParams
        .get("tags")
        .split(",")
        .map((ele) => parseInt(ele));
    }
    if (queryParams.has("createdDate")) {
      const dateArr = queryParams.get("createdDate")?.split("-");
      if (dateArr && dateArr.length === 2) {
        const startDate = new Date(dateArr[0]);
        const endDate = new Date(dateArr[1]);
        const dateTime = [
          DateTime.fromJSDate(startDate),
          DateTime.fromJSDate(endDate),
        ];
        filters["createdDate"] = dateTime;
      } else {
        filters["createdDate"] = [];
      }
    }
    return filters;
  };


  const [savedFilters, setSavedFilters] = React.useState(handleDefaultValues());

  const __handleDeleteSpecificValueFromQuery = ({ key, value }) => {
    const paramValues = queryParams
      .getAll(key)
      ?.map((item) => item.replace(/"/g, ''));

    const updatedValues = paramValues.filter((v) => v !== value);

    queryParams.delete(key);
    updatedValues.forEach((v) => queryParams.append(key, v));
  };

  const __handleSetDeletePaginationParams = (action) => {
    if (action === 'set') {
      queryParams.set('start', '1');
      queryParams.set('take', queryParams.get('take') || '25');
    } else if (
      action === 'delete' &&
      activeTabKey === LeadFinderTabKey.SAVED_TAB
    ) {
      const queryParamLength = Array.from(queryParams.entries()).length;
      if (
        queryParamLength === 2 &&
        queryParams.has('start') &&
        queryParams.has('take')
      ) {
        queryParams.delete('start');
        queryParams.delete('take');
      }
    }
  };

  const handleGetAllKeysByFilterFromQuery = (filter: any) => {
    const uniqueKeys = new Set();

    return uniqueKeys;
  };

  const handleIndividualTag = (tag: any) => {
    __handleDeleteSpecificValueFromQuery({ key: tag?.key, value: tag?.value });

    __handleSetDeletePaginationParams('delete');

    history.push({
      search: queryParams.toString(),
      hash: getLeadsActiveTab(location),
    });
  };

  const handleQuery = ({
    filterKey,
    filterValue,
    filterType,
  }: {
    filterKey: string;
    filterValue: any;
    filterType: string;
  }) => {
    if (filterKey) {
      __handleSetDeletePaginationParams('set');
    }

    // eslint-disable-next-line default-case
    switch (filterType) {
      case FilterComponentType.SEARCH_INPUT:
        if (filterValue !== '') {
          queryParams.set(filterKey, [`${filterValue}`].toString());
        } else {
          queryParams.delete(filterKey);
        }
        break;
      case FilterComponentType.SAVED_LEAD_DATE:
        if (filterValue?.createdDate?.length) {
          const dateObjects = filterValue?.createdDate;
          queryParams.set(filterKey, `${dateObjects.join('-')}`);
        } else {
          queryParams.delete(filterKey);
        }
        break;
      case FilterComponentType.TAG_CHECKBOX:
        if (filterValue?.tags?.length > 0) {
          queryParams.set(filterKey, `${filterValue?.tags.join(',')}`);
        } else {
          queryParams.delete(filterKey);
        }
        break;
    }

    __handleSetDeletePaginationParams('delete');

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      history.push({
        search: queryParams.toString(),
        hash: getLeadsActiveTab(location),
      });
    }, 0);
  };

  const handleDeleteFilter = (filter) => {
    if (filter) {
      filter?.type.forEach((type) => {
        queryParams.delete(type?.key);
        queryParams.delete(type?.excludeKey);
      });
      const queryParamLength = Array.from(queryParams.entries()).length;
      if (
        queryParamLength === 2 &&
        queryParams.has('start') &&
        queryParams.has('take')
      ) {
        queryParams.delete('start');
        queryParams.delete('take');
      }
      history.push({
        search: queryParams.toString(),
        hash: getLeadsActiveTab(location),
      });
    }
  };

  return {
    handleQuery,
    handleDeleteFilter,
    handleGetAllKeysByFilterFromQuery,
    handleIndividualTag,
    handleDefaultValues,
    savedFilters,
    setSavedFilters,
  };
};
