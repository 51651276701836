import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import IndividualLeads from '../individual-leads/individual-leads';
import Pagination from '../../../../shared/design-system/components/pagination/pagination';
import { showingLengthPerPageOptions } from '../../helpers/helper';
import Select from '../../../../shared/design-system/components/select';
import { TablePageLengthDropDown } from '../../../../shared/design-system/components/organisms/table/types';
import { Placement } from '../../../../shared/design-system/components/overlay';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import {
  sendGetDetailsOfContactRequestHandler,
  getTagsFilterApplied,
} from '../../../prospect/helpers/helper';
import { setProspectDetails } from '../../../prospect/prospect-slice';
import { RootState } from '../../../../store/root-reducer';

import ProspectDetails from '../../../prospect/components/prospect-list/components/prospect-details';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { RR_PAGINATION_LIMIT } from '../../type';

const LeadListing = (props: any) => {
  const {
    isLoading,
    leadsData,
    handlePagination,
    handleCheckUnCheckSingleLead,
    selectedLeads,
    setSelectedLeads,
    isBulkRevealing,
    apiParam,
    setLeadsData,
    handleAppendLead,
    disabledLeads,
  } = props;

  const currentPage = Math.ceil(Number(apiParam?.start) / apiParam?.take);
  const totalPages = Math.ceil(
    Number(Math.min(leadsData?.pagination?.total, RR_PAGINATION_LIMIT)) / apiParam?.take,
  );

  const {
    singleProspect,
    singleProspectSequenceDetails,
    getSingleProspectDetailsRequest,
    getSingleProspectSequencesDetailsRequest,
    prospectsFilters,
  } = useSelector((state: RootState) => state.prospect);

  const [selectedContactId, setSelectedContactId] = useState<number>(null);
  const [
    showProspectDetailsModal,
    setShowProspectDetailsModal,
  ] = useState<boolean>(false);

  const viewShowProspectDetailsModal = () => {
    if (!hasPermission(Permissions.PROSPECT_READ)) {
      return;
    }
    setShowProspectDetailsModal(true);
  };

  const getContactId = (id) => {
    sendGetDetailsOfContactRequestHandler({
      id,
      setProspectDetails,
      setSelectedContactId,
      viewShowProspectDetailsModal,
    });
  };

  const hideShowProspectDetailsModal = () => {
    setShowProspectDetailsModal(false);
  };

  const resetSelectedId = () => {
    setSelectedContactId(null);
  };

  useEffect(() => {
    if (leadsData) {
      console.log('Lead data:', leadsData);
      console.log('Total leads:', leadsData.pagination.total);
      console.log('Leads on current page:', leadsData.profiles.length);
    }
  }, [leadsData]);

  return (
    <div>
      <div
        className={`lead-listing-container ${
          leadsData?.pagination?.total < 25 ? 'lead-listing-container-less' : ''
        }`}
      >
        {!isLoading && leadsData && (
          <IndividualLeads
            apiParam={apiParam}
            setLeadsData={setLeadsData}
            leadSampleData={leadsData?.profiles}
            handleCheckUnCheckSingleLead={handleCheckUnCheckSingleLead}
            selectedLeads={selectedLeads}
            setSelectedLeads={setSelectedLeads}
            isBulkRevealing={isBulkRevealing}
            handleAppendLead={handleAppendLead}
            disabledLeads={disabledLeads}
            viewShowProspectDetailsModal={(id: number) => getContactId(id)}
          />
        )}
      </div>

      {showProspectDetailsModal && selectedContactId && (
        <ProspectDetails
          show={showProspectDetailsModal}
          onHide={hideShowProspectDetailsModal}
          selectedProspectId={selectedContactId}
          resetSelectedProspectId={resetSelectedId}
          selectedProspectData={singleProspect}
          selectedProspectSequences={singleProspectSequenceDetails}
          getSingleProspectDetailsStatus={
            getSingleProspectDetailsRequest.status
          }
          getSingleProspectSequencesDetailsStatus={
            getSingleProspectSequencesDetailsRequest.status
          }
          isTagsFilterApplied={getTagsFilterApplied({ prospectsFilters })}
        />
      )}
      {leadsData && leadsData?.pagination?.total > 25 && !isLoading && (
        <div
          className={classNames([
            'pagination-container d-flex',
            {
              'pagination-container-new': 1212,
            },
          ])}
        >
          <div className="pagination-row lead-pagination w-100 d-flex align-items-center justify-content-end">
            <div className="pagination-select-count w-100 d-flex align-items-center justify-content-end">
              <span className="regular-2 popover-arrow-color-txt mr-2">
                Showing
              </span>
              <Select<TablePageLengthDropDown>
                // options={handleGetShowingLengthPerPageOptions(Number(leadsData?.pagination?.total))}
                options={showingLengthPerPageOptions}
                selectedOptionKey={apiParam.take.toString()}
                selectedOptionRenderer={([option]) => (
                  <span className="blue-txt-15">{option?.key}</span>
                )}
                onChange={([option]) => {
                  handlePagination({
                    start: 1, // apiParam?.start,
                    take: Number(option.value),
                  });
                }}
                optionRenderer={(option) => <span>{option?.value}</span>}
                placeholder="Select Plan"
                placement={Placement.Top}
              />
              <span className="regular-2 popover-arrow-color-txt d-flex ml-2">{`out of ${leadsData?.pagination?.total}`}</span>
            </div>

            <div className="divider" />

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChange={(e) => {
                handlePagination({
                  start: e * apiParam?.take - (apiParam?.take - 1),
                  take: apiParam?.take,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadListing;
