import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getFilterValuesWithExtraValues,
  getLeadsActiveTab,
  showingLengthPerPageOptions,
} from '../../helpers/helper';
import {
  DEPARTMENT_DATA,
  INDUSTRY_DATA,
  LOCATION_DATA,
} from '../../helpers/filter-data';
import { getLeads, getSavedLeads } from '../../helpers/leads.api';
import useFirstEffect from '../../../../shared/hooks/useFirstEffect';
import { LeadFinderTabKey } from '../../type';
import { RootState } from '../../../../store/root-reducer';
import toaster from '../../../../shared/toaster';
import { dateToString } from '../../../../shared/functions/date';

export const useLeadFinder = (tabKey, setSelectedLeads) => {
  const history = useHistory();
  const location = useLocation();

  const userEmail = useSelector((state: RootState) => state?.home?.email);

  const queryParams = new URLSearchParams(location?.search);

  const [isLoading, setIsLoading] = useState(false);

  const [urlState, setUrlState] = useState<any>();
  const [apiParam, setApiParam] = useState<any>({
    start:
      isNaN(Number(queryParams.get('start'))) ||
      Number(queryParams.get('start')) < 1
        ? 1
        : Number(queryParams.get('start')),
    take:
      isNaN(Number(queryParams.get('take'))) ||
      Number(queryParams.get('take')) < 1
        ? 25
        : Number(queryParams.get('take')),
  });
  const [leadsData, setLeadsData] = useState({
    profiles: [],
    pagination: null,
  });

  const handleGeneratePayload = () => {
    const paramArray = Array.from(queryParams.entries());
    const tempParam = {};

    paramArray.forEach(([key, value]) => {
      switch (key) {
        case 'keyword':
          if (!value) {
            tempParam['start'] = 1;
            tempParam['take'] = 25;
          } else {
            tempParam[key] = value;
          }
          break;
        case 'tags':
          if (!value) {
            tempParam['start'] = 1;
            tempParam['take'] = 25;
          } else {
            tempParam[key] = value;
          }
          break;
        case 'createdDate':
          if (!value) {
            tempParam['start'] = 1;
            tempParam['take'] = 25;
          } else {
            const [formattedStartDate, formattedEndDate] = value.split('-');
            const startDate = new Date(formattedStartDate);
            const endDate = new Date(formattedEndDate);
            const dateObjects = [
              encodeURIComponent(dateToString(startDate)),
              encodeURIComponent(dateToString(endDate)),
            ];
            tempParam[key] = dateObjects.join(',');
          }
          break;
        case 'name':
        case 'link':
        case 'contactInfo':
        case 'jobChangeRange':
        case 'yearsExperience':
        case 'companySize':
        case 'companyRevenue':
          tempParam[key] = value;
          break;
        case 'employer':
        case 'excludeEmployer':
        case 'skills':
        case 'excludeSkills':
        case 'jobTitle':
        case 'excludeJobTitle':
        case 'managementLevels':
        case 'excludeManagementLevels':
        case 'sicCode':
        case 'excludeSicCode':
        case 'naicsCode':
        case 'excludeNaicsCode':
        case 'major':
        case 'excludeMajor':
        case 'school':
        case 'excludeSchool':
        case 'degree':
        case 'excludeDegree':
          tempParam[key] = queryParams.getAll(key); // Convert to array
          break;
        case 'department':
        case 'excludeDepartment':
          // Use getAll to handle multiple values for 'department'
          tempParam[key] = getFilterValuesWithExtraValues(
            queryParams.getAll(key),
            DEPARTMENT_DATA,
          );
          break;
        case 'location':
        case 'excludeLocation':
          // Use getAll to handle multiple values for 'location'
          const locationPayload = [];
          getFilterValuesWithExtraValues(
            queryParams.getAll(key),
            LOCATION_DATA,
          ).forEach((item) => {
            if (
              LOCATION_DATA.some((data) => {
                return data.key === item.substring(1, 3).toUpperCase();
              })
            ) {
              locationPayload.push(item.replace(/"/g, ''));
            } else {
              locationPayload.push(item);
            }
          });

          tempParam[key] = getFilterValuesWithExtraValues(
            locationPayload,
            LOCATION_DATA,
            true,
          );
          break;
        case 'industry':
        case 'excludeIndustry':
          // Use getAll to handle multiple values for 'location'
          tempParam[key] = getFilterValuesWithExtraValues(
            queryParams.getAll(key),
            INDUSTRY_DATA,
          );
          break;
        default:
      }
    });

    return tempParam;
  };

  const handleFetchLeads = async () => {
    setIsLoading(true);
    setSelectedLeads([]);
    try {
      let res: any = {};
      if (tabKey === LeadFinderTabKey.PEOPLE_TAB) {
        res = await getLeads(apiParam);
      } else {
        res = await getSavedLeads(apiParam);
      }
      if (res?.payload) {
        setIsLoading(false);
        setLeadsData(res.payload);
      }
    } catch (e: any) {
      setIsLoading(false);
      toaster.error(e?.message);
    }
  };

  const handleGenerateAPIParam = async () => {
    const payload: any = await handleGeneratePayload();
    setApiParam(() => ({
      start:
        isNaN(Number(queryParams.get('start'))) ||
        Number(queryParams.get('start')) < 1
          ? 1
          : Number(queryParams.get('start')),
      take:
        isNaN(Number(queryParams.get('take'))) ||
        Number(queryParams.get('take')) < 1
          ? 25
          : Number(queryParams.get('take')),
      ...payload,
    }));
  };

  console.log('leadData:', leadsData);

  const handleRecentSearch = () => {
    const payload: any = handleGeneratePayload();
    const meaningfulParams = Object.keys(payload).filter(
      (key) => key !== 'start' && key !== 'take',
    );

    if (meaningfulParams.length > 0) {
      if (!userEmail) {
        return;
      }

      const storedUrlsKey = 'storedUrls';
      const storedUrls = JSON.parse(localStorage.getItem(storedUrlsKey)) || {};

      if (!storedUrls[userEmail]) {
        storedUrls[userEmail] = [];
      }

      const currentUrlWithParams =
        window.location.pathname + window.location.search;
      const timestamp = new Date().toISOString();

      const existingUrlIndex = storedUrls[userEmail].findIndex(
        (urlData) =>
          urlData?.url?.toLowerCase() === currentUrlWithParams?.toLowerCase(),
      );

      const maxUrls = 4;

      if (existingUrlIndex !== -1) {
        const updatedEntry = {
          url: currentUrlWithParams,
          timestamp,
          payload,
        };

        storedUrls[userEmail].unshift(
          storedUrls[userEmail].splice(existingUrlIndex, 1)[0],
        );
        storedUrls[userEmail][0] = updatedEntry;

        storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
      } else {
        const urlData = {
          url: currentUrlWithParams,
          timestamp,
          payload,
        };

        storedUrls[userEmail].unshift(urlData);

        storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
      }

      localStorage.setItem(storedUrlsKey, JSON.stringify(storedUrls));

      const paramArray = Array.from(queryParams.entries());

      // eslint-disable-next-line  @typescript-eslint/no-unused-vars
      paramArray.forEach(([key]) => {
        queryParams.delete(key);
      });

      history.push({
        search: queryParams.toString(),
        hash: getLeadsActiveTab(location),
      });
    }
  };

  const handleRouteChange = () => {
    const payload: any = handleGeneratePayload();
    const meaningfulParams = Object.keys(payload).filter(
      (key) => key !== 'start' && key !== 'take',
    );

    if (meaningfulParams.length > 0) {
      if (!userEmail) {
        return;
      }

      const storedUrlsKey = 'storedUrls';
      const storedUrls = JSON.parse(localStorage.getItem(storedUrlsKey)) || {};

      if (!storedUrls[userEmail]) {
        storedUrls[userEmail] = [];
      }

      const currentUrlWithParams = location.pathname + location.search;
      const timestamp = new Date().toISOString();

      const existingUrlIndex = storedUrls[userEmail].findIndex(
        (urlData) =>
          urlData?.url?.toLowerCase() === currentUrlWithParams?.toLowerCase(),
      );

      const maxUrls = 4;

      if (existingUrlIndex !== -1) {
        const updatedEntry = {
          url: currentUrlWithParams,
          timestamp,
          payload,
        };

        storedUrls[userEmail].unshift(
          storedUrls[userEmail].splice(existingUrlIndex, 1)[0],
        );
        storedUrls[userEmail][0] = updatedEntry;

        storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
      } else {
        const urlData = {
          url: currentUrlWithParams,
          timestamp,
          payload,
        };

        storedUrls[userEmail].unshift(urlData);

        storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
      }

      localStorage.setItem(storedUrlsKey, JSON.stringify(storedUrls));
    }
  };

  const handlePagination = ({ start, take }) => {
    if (start && take) {
      queryParams.set('start', start);
      queryParams.set('take', take);
      history.push({
        search: queryParams.toString(),
        hash: getLeadsActiveTab(location),
      });
    }
  };

  const handleAppendLead = (lead) => {
    const indexOfProfileToReplace = leadsData?.profiles?.findIndex(
      (profile) => profile?.id === lead?.id,
    );

    if (indexOfProfileToReplace !== -1) {
      const updatedProfiles = [...leadsData.profiles];
      updatedProfiles[indexOfProfileToReplace] = lead;

      setLeadsData({
        ...leadsData,
        profiles: updatedProfiles,
      });
    }
  };

  useFirstEffect(() => {
    handleFetchLeads();
  }, [apiParam]);

  useEffect(() => {
    if (location?.search) {
      handleGenerateAPIParam();
      setUrlState({
        payload: handleGeneratePayload(),
        url: location?.pathname + location?.search,
      });
    } else {
      setLeadsData({
        profiles: [],
        pagination: null,
      });
    }
  }, [location.search]);

  useEffect(
    () => () => {
      if (!urlState || window.location?.pathname === '/leads') {
        return; // If urlState is not set, do nothing
      }

      const { payload } = urlState;
      const meaningfulParams = Object.keys(payload)?.filter(
        (key) => key !== 'start' && key !== 'take',
      );

      if (meaningfulParams.length > 0 && userEmail) {
        const storedUrlsKey = 'storedUrls';
        const storedUrls =
          JSON.parse(localStorage.getItem(storedUrlsKey)) || {};

        if (!storedUrls[userEmail]) {
          storedUrls[userEmail] = [];
        }

        const timestamp = new Date().toISOString();

        const existingUrlIndex = storedUrls[userEmail].findIndex(
          (urlData) =>
            urlData?.url?.toLowerCase() === urlState?.url?.toLowerCase(),
        );

        const maxUrls = 4;

        if (existingUrlIndex !== -1) {
          const updatedEntry = {
            url: urlState?.url,
            timestamp,
            payload,
          };

          storedUrls[userEmail].unshift(
            storedUrls[userEmail].splice(existingUrlIndex, 1)[0],
          );
          storedUrls[userEmail][0] = updatedEntry;

          storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
        } else {
          const urlData = {
            url: urlState?.url,
            timestamp,
            payload,
          };

          storedUrls[userEmail].unshift(urlData);

          storedUrls[userEmail] = storedUrls[userEmail].slice(0, maxUrls);
        }

        localStorage.setItem(storedUrlsKey, JSON.stringify(storedUrls));
      }
    },
    [urlState],
  );

  return {
    isLoading,
    leadsData,
    apiParam,
    handlePagination,
    handleRecentSearch,
    handleGeneratePayload,
    handleAppendLead,
    setLeadsData,
    handleRouteChange,
  };
};
